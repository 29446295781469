import { SunIcon } from '@heroicons/react/24/outline';
import dayjs, { Dayjs } from 'dayjs';
import { addBreadcrumb, captureException } from '@sentry/nextjs';
import { FunctionComponent, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { CalendarPopupWrapper } from '../Common/CalendarPopupWrapper';
import useUserPermission from '../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../typings/roleConfig';
import { idContainer } from '../../../../utilities/idContainer';
import { useCalendarOverrideContext } from '../../../../context/calendarOverrideContext';
import { BasicSelectInput } from '../../../Forms/FormFields/BasicSelectInput';
import { formatName } from '../../../../utilities/formatName';
import { hasWriteAccessTo } from '../../../../utilities/access-rights/hasWriteAccessTo';
import { useUserTeam } from '../../../../hooks/useUserTeam';
import useContract from '../../../../hooks/useContract';
import { classNames } from '../../../../utilities/classNames';
import { BottomButtonRow } from './BottomButtonRow';
import { EventCreateUpdatePopupProps } from './EventCreateUpdatePopup';
import {
  checkForOverlappingVacations,
  validateVacation,
} from '@components/Calendar/helpers/CalendarHelpers';
import { BasicTextInput } from '@components/Forms/FormFields/BasicTextInput';
import { DateRangeInputs } from '@components/Forms/FormFields/DateRangeInputs';
import {
  BasicUserInfo,
  CreateVacationEventsDto,
  Resource,
  UpdateVacationEventsDto,
  VacationEvent,
} from '@tr-types/backend-types';
import { useErrorPopupContext } from 'context/errorPopupContext';
import authenticatedPost from 'data/authenticatedPost';
import { HttpEndpoints } from 'data/httpEndpoints';
import { useAppContext } from 'context/appContext';
import authenticatedFetcher from 'data/authenticatedFetcher';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';
import { useConfirmPopupContext } from 'context/confirmPopupContext';

interface PopupFormElements {
  from: HTMLInputElement;
  to: HTMLInputElement;
  description: HTMLInputElement;
  userId?: HTMLSelectElement;
}

export const VacationEventCreateUpdatePopup: FunctionComponent<
  EventCreateUpdatePopupProps
> = ({
  calendarSelection,
  position,
  onClose,
  onCreateOrUpdate,
  setIsLoading,
}: EventCreateUpdatePopupProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.createUpdateVacation',
  });

  const { organizationId, user: loggedInUser } = useAppContext();
  const userTeam = useUserTeam();
  const { contract } = useContract();

  const canDeleteAnyEvent = useUserPermission(
    Permission.ALL_CALENDARS,
    Scope.READ_WRITE_DELETE,
  );
  const canDeleteOwnVacationEvents = useUserPermission(
    Permission.OWN_VACATION,
    Scope.READ_WRITE_DELETE,
  );
  const canEditOwnVacation = useUserPermission(
    Permission.OWN_VACATION,
    Scope.READ_WRITE,
  );
  const { overrideUsers } = useCalendarOverrideContext();
  const userOptions = useMemo(() => {
    return overrideUsers.filter(
      (u) =>
        (u.id != loggedInUser.id || canEditOwnVacation) &&
        hasWriteAccessTo(
          Resource.Calendar,
          loggedInUser,
          userTeam,
          u,
          contract,
        ),
    );
  }, [overrideUsers, loggedInUser, userTeam, contract, canEditOwnVacation]);

  const { data: fetchedEvent } = useSWR<VacationEvent>(
    () =>
      calendarSelection &&
      HttpEndpoints.VacationEventEndpoints.getVacationEventById(
        calendarSelection.eventId,
        calendarSelection.userId,
      ),
    authenticatedFetcher,
  );

  const canDeleteEvent = useMemo(() => {
    if (!fetchedEvent) return false;
    if (fetchedEvent.user.id === loggedInUser.id) {
      return canDeleteOwnVacationEvents;
    }
    return canDeleteAnyEvent;
  }, [
    canDeleteAnyEvent,
    canDeleteOwnVacationEvents,
    loggedInUser,
    fetchedEvent,
  ]);

  const { setErrorMessage: setError } = useErrorPopupContext();

  const isUpdate = !!calendarSelection.eventId;

  const [startDate, setStartDate] = useState(dayjs(calendarSelection.start));
  const [endDate, setEndDate] = useState(
    dayjs(calendarSelection.end).subtract(1, 'day'),
  );
  const { confirm } = useConfirmPopupContext();
  const canEditPastEvents = useUserPermission(
    Permission.EDIT_PAST_EVENTS,
    Scope.READ_WRITE,
  );

  async function onSubmit(
    e: React.FormEvent<HTMLFormElement & PopupFormElements>,
  ): Promise<void> {
    addBreadcrumb({
      category: 'calendar',
      message: `Submitting vacation event form`,
    });
    e.preventDefault();
    setIsLoading(true);
    const target = e.currentTarget;

    const vacationStart: Dayjs = startDate.startOf('day');
    const vacationEnd: Dayjs = endDate.add(1, 'days').startOf('day');
    const userId = target.userId?.value || calendarSelection.userId;

    const doVacationsOverlap = await checkForOverlappingVacations(
      userId,
      fetchedEvent ??
        ({
          start_time: vacationStart,
          end_time: vacationEnd,
        } as unknown as VacationEvent),
      {
        start: vacationStart,
        end: vacationEnd,
      },
    );
    if (doVacationsOverlap) {
      setError(t('errorVacationOverlap'));
      setIsLoading(false);
      return;
    }

    if (
      !(await validateVacation(
        organizationId,
        vacationStart,
        vacationEnd,
        confirm,
      ))
    ) {
      setIsLoading(false);
      return;
    }

    try {
      if (isUpdate) {
        const updatedVacationEntry: UpdateVacationEventsDto = {
          description: target.description.value,
          start_time: vacationStart.toISOString(),
          end_time: vacationEnd.toISOString(),
        };
        addBreadcrumb({
          category: 'calendar',
          message: `Updating vacation event ${calendarSelection.eventId}`,
          data: updatedVacationEntry,
        });
        await authenticatedPost(
          HttpEndpoints.VacationEventEndpoints.patchVacationEvent(
            calendarSelection.eventId,
            userId,
          ),
          updatedVacationEntry,
          'PATCH',
        );
      } else {
        const newVacationEntry: CreateVacationEventsDto = {
          description: target.description.value,
          start_time: vacationStart.toISOString(),
          end_time: vacationEnd.toISOString(),
          user: idContainer(userId),
          organization: { id: organizationId },
        };
        addBreadcrumb({
          category: 'calendar',
          message: `Creating vacation event`,
          data: newVacationEntry,
        });

        await authenticatedPost(
          HttpEndpoints.VacationEventEndpoints.postVacationEvent(userId),
          newVacationEntry,
        );
      }
    } catch (err) {
      setError(
        isUpdate
          ? t('errorUpdateVacationGeneric')
          : t('errorCreateVacationGeneric'),
      );
      setIsLoading(false);
      captureException(err);
    }
    onClose();
    onCreateOrUpdate?.();
    return;
  }

  async function deleteVacationEvent() {
    addBreadcrumb({
      category: 'calendar',
      message: `Deleting vacation event ${calendarSelection.eventId}`,
    });
    if (!isUpdate || !calendarSelection.eventId) return;

    setIsLoading(true);
    try {
      await authenticatedFetcher(
        HttpEndpoints.VacationEventEndpoints.deleteVacationEvent(
          calendarSelection.eventId,
          calendarSelection.userId,
        ),
        'DELETE',
      );

      onCreateOrUpdate?.();
      onClose();
    } catch (err) {
      setError(t('errorDeleteVacationGeneric'));
      setIsLoading(false);
      captureException(err);
    }
  }

  return (
    <CalendarPopupWrapper
      position={position}
      onClose={onClose}
      icon={SunIcon}
      title={isUpdate ? t('editTitle') : t('createTitle')}
      // Since this component is only displayed when this condition is true, this is always true
      isOpen={true}
    >
      {calendarSelection.eventId && !fetchedEvent ? (
        <LoadingIndicator />
      ) : (
        <form className="space-y-8 divide-gray-200" onSubmit={onSubmit}>
          <div className="space-y-8 divide-gray-200 sm:space-y-5">
            <DateRangeInputs
              onStartChange={setStartDate}
              onEndChange={setEndDate}
              defaultEnd={endDate}
              defaultStart={startDate}
              minEnd={startDate}
              maxStart={endDate}
              minStart={canEditPastEvents ? undefined : dayjs()}
            />

            <BasicTextInput
              small
              label={t('descriptionLabel')}
              formName="description"
              type="text"
              value={fetchedEvent?.description}
            />
            {!isUpdate && (
              <div className={classNames(userOptions.length === 1 && 'hidden')}>
                <BasicSelectInput<BasicUserInfo>
                  label={t('userLabel')}
                  formName="userId"
                  value={userOptions[0].id}
                  options={userOptions}
                  required
                  bindLabel={(user) => formatName(user)}
                  bindValue={(user) => user.id}
                  small
                />
              </div>
            )}
          </div>
          <BottomButtonRow
            onDelete={isUpdate && canDeleteEvent && deleteVacationEvent}
            onClose={onClose}
          />
        </form>
      )}
    </CalendarPopupWrapper>
  );
};
