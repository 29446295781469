import {
  FormEvent,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import dayjs, { Dayjs } from 'dayjs';
import { addBreadcrumb } from '@sentry/nextjs';
import update from 'lodash/update';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import router from 'next/router';
import { CalendarPopupWrapper } from '../Common/CalendarPopupWrapper';
import { idContainer } from '../../../../utilities/idContainer';
import { useConfirmPopupContext } from '../../../../context/confirmPopupContext';
import { CustomError } from '../../../../data/customError';
import { markLessonRequestAsCompleted } from '../../../../utilities/lesson-requests/markLessonRequestAsCompleted';
import { useNotificationContext } from '../../../../context/notificationContext';
import useUserPermission from '../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../typings/roleConfig';
import { hasWriteAccessTo } from '../../../../utilities/access-rights/hasWriteAccessTo';
import { useUserTeam } from '../../../../hooks/useUserTeam';
import useContract from '../../../../hooks/useContract';
import { BottomButtonRow } from './BottomButtonRow';
import { EventCreateUpdatePopupProps } from './EventCreateUpdatePopup';
import {
  CreateStudentDto,
  CreateWorkEventDto,
  WorkEventDefaultValues,
  LicenseCategory,
  Vehicle,
  WorkEvent,
  StudentGroup,
  LessonRequest,
  Resource,
  AccessRight,
  Student,
} from '@tr-types/backend-types';
import {
  getStudentFormValues,
  StudentFormElements,
} from '@components/Forms/students/StudentFormFields';
import {
  WorkEventForm,
  WorkEventFormState,
} from '@components/Calendar/Forms/WorkEventForm';

import {
  BasicTextInput,
  dateInputFormat,
} from '@components/Forms/FormFields/BasicTextInput';

import { useAppContext } from 'context/appContext';
import { useErrorPopupContext } from 'context/errorPopupContext';
import authenticatedFetcher from 'data/authenticatedFetcher';
import authenticatedPost from 'data/authenticatedPost';
import { HttpEndpoints } from 'data/httpEndpoints';
import containsAllNull from 'utilities/containsAllNull';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';
import { useCalendarOverrideContext } from 'context/calendarOverrideContext';
import { partialSetState } from 'utilities/partialSetState';
import { getTimeSpan } from '@components/Calendar/helpers/CalendarHelpers';
import { displayDateFormat } from 'utilities/dateFormat';

interface PopupFormElements extends StudentFormElements {
  from: HTMLInputElement;
  to: HTMLInputElement;
  description: HTMLInputElement;
  billingType: HTMLInputElement;
  secondaryVehicle: HTMLSelectElement;
  meetingPoint: HTMLSelectElement;
  student: HTMLInputElement;
  instructor?: HTMLInputElement;
}

export const WorkEventCreateUpdatePopup: FunctionComponent<
  EventCreateUpdatePopupProps
> = ({
  calendarSelection,
  position,
  onClose,
  onCreateOrUpdate,
  setIsLoading,
}: EventCreateUpdatePopupProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.createUpdateWorkEvent',
  });
  const { t: errorTranslate } = useTranslation('translation', {
    keyPrefix: 'error',
  });

  const { setErrorMessage: setError } = useErrorPopupContext();
  const { setNotification } = useNotificationContext();
  const { confirm } = useConfirmPopupContext();
  const { organizationId, user: loggedInUser } = useAppContext();
  const userTeam = useUserTeam();
  const { contract } = useContract();
  const { overrideUsers, overrideVehicleId } = useCalendarOverrideContext();
  const canDeleteOwnEvents = useUserPermission(
    Permission.OWN_CALENDAR,
    Scope.READ_WRITE_DELETE,
  );
  const canDeleteAnyEvent = useUserPermission(
    Permission.ALL_CALENDARS,
    Scope.READ_WRITE_DELETE,
  );
  const canDeletePastEvents = useUserPermission(
    Permission.EDIT_PAST_EVENTS,
    Scope.READ_WRITE_DELETE,
  );
  const canSelectAnyVehicle = useUserPermission(
    Permission.CALENDAR_SELECT_ALL_VEHICLES,
    Scope.READ_WRITE,
  );

  const isUpdate = !!calendarSelection.eventId;

  const setFormFields = (
    setStateAction: SetStateAction<Partial<WorkEventFormState>>,
  ) => partialSetState(setFormState, setStateAction);

  const { data: fetchedEvent } = useSWR<WorkEvent>(
    () =>
      calendarSelection &&
      HttpEndpoints.WorkEventEndpoints.getWorkEventById(
        calendarSelection.eventId,
        calendarSelection.userId,
      ),
    authenticatedFetcher,
  );

  const canDeleteEvent = useMemo(() => {
    if (!fetchedEvent) return false;
    if (
      !canDeletePastEvents &&
      dayjs(fetchedEvent.start_time).isBefore(dayjs().startOf('day')) // past events = days before today
    ) {
      return false;
    }
    if (loggedInUser.id === fetchedEvent.user.id) return canDeleteOwnEvents;
    // check access rights
    if (
      loggedInUser.access_rights.some((right) => {
        return (
          right.accessed_user.id === fetchedEvent.user?.id &&
          right.resource === Resource.Calendar &&
          (right.access_right === AccessRight.Write ||
            right.access_right === AccessRight.ReadWrite)
        );
      })
    ) {
      return true;
    }
    return canDeleteAnyEvent;
  }, [
    canDeleteAnyEvent,
    canDeleteOwnEvents,
    fetchedEvent,
    loggedInUser,
    canDeletePastEvents,
  ]);

  const { data: lessonRequest } = useSWR<LessonRequest>(
    () =>
      calendarSelection.lessonRequestId &&
      HttpEndpoints.LessonRequestEndpoints.getLessonRequest(
        calendarSelection.lessonRequestId,
        organizationId,
      ),
    authenticatedFetcher,
  );

  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  const [formState, setFormState] = useState<WorkEventFormState>({
    startTime: '',
    endTime: '',
    userId: '',
    studentId: '',
    billingType: null,
    meetingPoint: null,
    meetingAddress: null,
    addNewStudent: false,
    specifyMeetingAddress: false,
    primaryVehicleId: overrideVehicleId,
    studentGroups: [],
    secondaryVehicleId: null,
    date: dayjs(fetchedEvent?.start_time || calendarSelection.start).format(
      dateInputFormat,
    ),
    licenseCategory: '',
  });

  const userOptions = useMemo(() => {
    return overrideUsers.filter((u) =>
      hasWriteAccessTo(Resource.Calendar, loggedInUser, userTeam, u, contract),
    );
  }, [overrideUsers, loggedInUser, userTeam, contract]);

  useEffect(() => {
    setFormFields({ userId: userOptions[0]?.id });
  }, [userOptions]);

  useEffect(() => {
    const start = dayjs(fetchedEvent?.start_time || calendarSelection.start);
    const end = dayjs(fetchedEvent?.end_time || calendarSelection.end);
    setFormFields(() => ({
      startTime: start.isValid() ? start.format('HH:mm') : '',
      endTime: end.isValid() ? end.format('HH:mm') : '',
      date: start.format(dateInputFormat),
    }));
  }, [calendarSelection, fetchedEvent]);

  useEffect(() => {
    if (!lessonRequest) return;
    if (lessonRequest.student) {
      setFormFields({
        studentDisabled: true,
        studentId: lessonRequest.student.id,
        licenseCategory: lessonRequest.licenseCategory,
      });
    } else {
      setFormFields({
        studentDisabled: true,
        addNewStudent: true,
        licenseCategory: lessonRequest.licenseCategory,
        newStudent: {
          firstName: lessonRequest.firstName,
          lastName: lessonRequest.lastName,
          email: lessonRequest.email,
          phone: lessonRequest.telephone,
        },
      });
    }
  }, [lessonRequest]);

  useEffect(() => {
    if (!fetchedEvent) return;

    const specifyMeetingAddress =
      fetchedEvent.meetingAddress &&
      !containsAllNull(fetchedEvent.meetingAddress);

    // Initialize the form with the fetched event data
    setFormFields({
      meetingPoint: fetchedEvent.meetingPoint,
      userId: fetchedEvent.user?.id,
      billingType: fetchedEvent.billingType,
      licenseCategory: fetchedEvent.billingType?.licenseCategory,
      specifyMeetingAddress,
      meetingAddress: fetchedEvent.meetingAddress,
      studentId: fetchedEvent.student?.id || '',
      primaryVehicleId: fetchedEvent.vehicle?.id || '',
      secondaryVehicleId: fetchedEvent.secondaryVehicle?.id || '',
    });
  }, [fetchedEvent]);

  useEffect(() => {
    void updateAvailableVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.billingType?.id, formState.startTime, formState.endTime]);

  // fill in default values from last event when the selected student changes
  useEffect(() => {
    if (
      !isUpdate &&
      formState.studentId &&
      (!formState.billingType ||
        !formState.primaryVehicleId ||
        !formState.secondaryVehicleId ||
        (!formState.meetingPoint && !formState.meetingPoint))
    ) {
      void updateFormFields();
    }
    // form fields should only be filled out automatically when the student changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.studentId]);

  async function createStudent(
    target: PopupFormElements,
    studentGroups: StudentGroup[],
  ): Promise<string> {
    const newStudent: CreateStudentDto = {
      ...getStudentFormValues(target),
      primaryAddress: lessonRequest?.address, // since the address field is not included in the work event form, insert it directly from the lesson request if given
      instructor: { id: target.instructor?.value || loggedInUser.id },
      groups: studentGroups,
    };
    addBreadcrumb({
      category: 'calendar',
      message: 'Creating new student',
      data: newStudent,
    });
    const res = await authenticatedPost(
      HttpEndpoints.StudentEndpoints.postStudent(organizationId),
      newStudent,
    );
    const id = (await res.json()).id;
    if (lessonRequest) {
      await authenticatedPost(
        HttpEndpoints.LessonRequestEndpoints.patch(
          lessonRequest.id,
          organizationId,
        ),
        { student: { id } },
        'PATCH',
      );
    }
    return id;
  }

  async function submitEvent(
    e: React.FormEvent<PopupFormElements & HTMLFormElement>,
  ): Promise<void> {
    addBreadcrumb({ category: 'calendar', message: 'Submitting event' });
    const target = e.currentTarget;
    let newStudentId: string = undefined;

    let eventStart: Dayjs = dayjs(formState.date);
    let eventEnd: Dayjs = dayjs(formState.date);

    const startTime: Dayjs = dayjs(target.from.value, 'HH:mm');
    const endTime: Dayjs = dayjs(target.to.value, 'HH:mm');

    eventStart = eventStart.set('hour', startTime.get('hour'));
    eventStart = eventStart.set('minute', startTime.get('minute'));

    eventEnd = eventEnd.set('hour', endTime.get('hour'));
    eventEnd = eventEnd.set('minute', endTime.get('minute'));

    if (formState.addNewStudent) {
      try {
        newStudentId = await createStudent(target, formState.studentGroups);
        if (!newStudentId) {
          setError(t('errorCreateStudentGeneric'));
          return;
        }
      } catch (err) {
        if (err instanceof CustomError && err.translationKey) {
          setError(errorTranslate(err.translationKey));
        } else {
          setError(t('errorCreateStudentGeneric'));
        }
        return;
      }
    }

    const studentIdForDto = newStudentId || formState.studentId || undefined;

    if (studentIdForDto) {
      const student = await authenticatedFetcher<Student>(
        HttpEndpoints.StudentEndpoints.getStudentById(studentIdForDto),
      );
      if (student?.dropoutItem) {
        try {
          await confirm({
            message: t('errorStudentIsDroppedOut'),
            title: t('studentNotActive'),
          });
        } catch (e) {
          return;
        }
      }
      if (student?.archived) {
        try {
          await confirm({
            message: t('errorStudentIsArchived'),
            title: t('studentNotActive'),
          });
        } catch (e) {
          return;
        }
      }
    }

    const newEvent: CreateWorkEventDto = {
      description: target.description.value || null,
      start_time: eventStart.toISOString(),
      end_time: eventEnd.toISOString(),
      billingType: idContainer(formState.billingType.id),
      organization: idContainer(organizationId),
      user: { id: formState.userId || loggedInUser.id },
      student: idContainer(studentIdForDto),
      vehicle: idContainer(formState.primaryVehicleId || null),
      secondaryVehicle: idContainer(formState.secondaryVehicleId || null),
      meetingPoint: idContainer(target.meetingPoint?.value || null),
    };

    if (!formState.specifyMeetingAddress) {
      newEvent.meetingAddress = {
        country: null,
        city: null,
        street: null,
        number: null,
        zip: null,
      };
    } else {
      newEvent.meetingAddress = formState.meetingAddress;
      newEvent.meetingPoint = null;
    }

    try {
      await patchOrPostEvent(newEvent);
    } catch (_) {
      setError(t('errorCreateEventGeneric'));
    }

    if (lessonRequest) {
      void markLessonRequestAsCompleted(
        lessonRequest.id,
        organizationId,
        setError,
        setNotification,
      );
      void router.replace(
        { query: { ...router.query, lessonRequestId: undefined } },
        undefined,
        {
          shallow: true,
        },
      );
    }
    onClose();
    onCreateOrUpdate?.();
  }

  function patchOrPostEvent(event: CreateWorkEventDto) {
    addBreadcrumb({
      category: 'calendar',
      message: 'Patching or posting event',
      data: { event, fetchedEvent },
    });
    if (isUpdate) {
      if (!fetchedEvent) {
        throw new Error(
          `Tried to update event, but fetchedEvent is ${fetchedEvent}. This error occured when updating with body ${JSON.stringify(
            event,
          )}`,
        );
      }
      return authenticatedPost(
        HttpEndpoints.WorkEventEndpoints.patchWorkEvent(
          fetchedEvent?.id,
          fetchedEvent?.user?.id,
        ),
        event,
        'PATCH',
      );
    } else {
      return authenticatedPost(
        HttpEndpoints.WorkEventEndpoints.postWorkEvent(event.user.id),
        event,
      );
    }
  }

  async function deleteCalendarEntry() {
    addBreadcrumb({
      category: 'calendar',
      message: `Deleting calendar entry ${calendarSelection.eventId} for user ${calendarSelection.userId}`,
    });
    if (!isUpdate || !calendarSelection.eventId) return;

    setIsLoading(true);
    try {
      await authenticatedFetcher(
        HttpEndpoints.WorkEventEndpoints.deleteWorkEvent(
          calendarSelection.eventId,
          calendarSelection.userId,
        ),
        'DELETE',
      );

      onCreateOrUpdate?.();
      onClose();
    } catch (_) {
      setError(t('errorDeleteEventGeneric'));
      setIsLoading(false);
    }
  }

  async function updateAvailableVehicles(): Promise<void> {
    if (!formState.billingType || !formState.billingType.licenseCategory) {
      return;
    }

    const timeSpan = getTimeSpan(
      formState.startTime,
      formState.endTime,
      formState.date,
    );

    if (!timeSpan) {
      setVehicles([]);
      return;
    }

    const licenseCategory =
      formState.billingType.licenseCategory != LicenseCategory.None
        ? formState.billingType.licenseCategory
        : undefined;

    let fetchedVehicles: Vehicle[] = [];
    if (canSelectAnyVehicle) {
      fetchedVehicles = await authenticatedFetcher(
        HttpEndpoints.VehicleEndpoints.getVehiclesForOrganization(
          loggedInUser.organization.id,
          {
            licenseCategory: licenseCategory,
            availabilityBegin: timeSpan.start.toISOString(),
            availabilityEnd: timeSpan.end.toISOString(),
            ignoreEvent: update ? fetchedEvent?.id : null,
          },
        ),
      );
    } else {
      fetchedVehicles = await authenticatedFetcher(
        HttpEndpoints.VehicleEndpoints.getVehiclesForInstructor(
          formState.userId,
          {
            licenseCategory: licenseCategory,
            availabilityBegin: timeSpan.start.toISOString(),
            availabilityEnd: timeSpan.end.toISOString(),
            ignoreEvent: update ? fetchedEvent?.id : null,
          },
        ),
      );
    }
    // If the selected vehicle is not available anymore, reset it
    if (
      formState.primaryVehicleId &&
      !fetchedVehicles.find((v) => v.id === formState.primaryVehicleId)
    ) {
      setFormFields({ primaryVehicleId: null });
    }
    if (
      formState.secondaryVehicleId &&
      !fetchedVehicles.find((v) => v.id === formState.secondaryVehicleId)
    ) {
      setFormFields({ secondaryVehicleId: null });
    }
    setVehicles(fetchedVehicles);
  }

  /**
   * Gets default values from the selected student's last event and fills the form with them
   */
  async function updateFormFields() {
    const timeSpan = getTimeSpan(
      formState.startTime,
      formState.endTime,
      formState.date,
    );

    if (!timeSpan) {
      return;
    }

    const meetingSpotIsFilled =
      !containsAllNull(formState.meetingAddress) || !!formState.meetingPoint;

    const defaultValues: WorkEventDefaultValues = await authenticatedFetcher(
      HttpEndpoints.WorkEventEndpoints.getEventDefaultValuesForStudent(
        formState.studentId,
        organizationId,
        {
          userId: formState.userId,
          billingTypeId: formState.billingType?.id,

          primaryVehicleId: formState.primaryVehicleId,
          secondaryVehicleId: formState.secondaryVehicleId,

          start: timeSpan.start.toISOString(),
          end: timeSpan.end.toISOString(),

          meetingSpotIsFilled: meetingSpotIsFilled,
        },
      ),
    );

    if (defaultValues.billingType) {
      setFormFields({
        billingType: defaultValues.billingType,
        licenseCategory: defaultValues.billingType.licenseCategory,
      });
    }
    if (defaultValues.eventEnd) {
      setFormFields({
        endTime: dayjs(defaultValues.eventEnd).format('HH:mm'),
      });
    }
    if (defaultValues.primaryVehicle) {
      setFormFields({ primaryVehicleId: defaultValues.primaryVehicle.id });
    }
    if (defaultValues.secondaryVehicle) {
      setFormFields({ secondaryVehicleId: defaultValues.secondaryVehicle.id });
    }
    if (defaultValues.meetingPoint) {
      setFormFields({ meetingPoint: defaultValues.meetingPoint });
    } else if (!containsAllNull(defaultValues.meetingAddress)) {
      setFormFields({
        specifyMeetingAddress: true,
        meetingAddress: defaultValues.meetingAddress,
      });
    }
  }

  return (
    <CalendarPopupWrapper
      position={position}
      onClose={onClose}
      icon={CalendarIcon}
      title={t(isUpdate ? 'editTitle' : 'createTitle', {
        date: dayjs(formState.date).format(displayDateFormat),
      })}
      // Since this component is only displayed when this condition is true, this is always true
      isOpen={true}
    >
      {calendarSelection.eventId && !fetchedEvent ? (
        <LoadingIndicator />
      ) : (
        <form
          className="space-y-8 divide-gray-200"
          onSubmit={(e: FormEvent<PopupFormElements & HTMLFormElement>) => {
            e.preventDefault();
            if (submitIsLoading) return;
            setIsLoading(true);
            setSubmitIsLoading(true);
            submitEvent(e).finally(() => {
              setSubmitIsLoading(false);
              setIsLoading(false);
            });
          }}
        >
          <div className="space-y-8 divide-gray-200 sm:space-y-5">
            <WorkEventForm
              workEventFormState={formState}
              setWorkEventFormState={setFormState}
              lessonRequest={lessonRequest}
              vehicles={vehicles}
              userOptions={userOptions}
            />
            <BasicTextInput
              small
              required={formState.billingType?.requiresDescription}
              label={t('descriptionLabel')}
              formName="description"
              type="text"
              value={fetchedEvent?.description}
            />
          </div>

          <BottomButtonRow
            submitLoading={submitIsLoading}
            saveDisabled={
              !formState.primaryVehicleId &&
              formState.billingType?.licenseCategory !== LicenseCategory.None
            }
            onDelete={isUpdate && canDeleteEvent && deleteCalendarEntry}
            onClose={onClose}
          />
        </form>
      )}
    </CalendarPopupWrapper>
  );
};
