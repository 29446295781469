import { createContext, useContext } from 'react';
import { BasicUserInfo, User } from '@tr-types/backend-types';

interface CalendarOverrideContextState {
  // "overrideUser" is the user selected in the dropdown above the calendar
  // this overrides the currently logged in user as the default user upon event creation
  overrideUsers?: BasicUserInfo[];
  // The two properties below provide a default value that is used when creating an event
  // "overrideVehicleId" is the vehicle selected in the dropdown above the calendar
  overrideVehicleId?: string;
}

const CalendarOverrideContext = createContext<
  CalendarOverrideContextState | undefined
>(undefined);

interface Props extends CalendarOverrideContextState {
  children: React.ReactNode;
}

export function CalendarOverrideContextWrapper({
  children,
  overrideUsers,
  overrideVehicleId,
}: Props): JSX.Element {
  return (
    <CalendarOverrideContext.Provider
      value={{
        overrideUsers,
        overrideVehicleId,
      }}
    >
      {children}
    </CalendarOverrideContext.Provider>
  );
}

export function useCalendarOverrideContext(): CalendarOverrideContextState {
  return useContext(CalendarOverrideContext);
}
