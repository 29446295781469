import FullCalendar, {
  AllowFunc,
  CalendarOptions,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/react';
import { Fragment, FunctionComponent, RefObject, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../utilities/classNames';
import { commonButtonClassesWithoutPadding } from '../Common/Buttons/Button';
import { BasicTextInput } from '../Forms/FormFields/BasicTextInput';
import {
  formatCalendarRangeTitle,
  isHoliday,
  isVacation,
  renderEventContent,
} from './helpers/CalendarHelpers';
import {
  CalendarView,
  getCalendarView,
  viewToViewType,
} from './helpers/getCalendarView';
import { MobileMonthCalendar } from './MobileMonthCalendar';
import useWindowDimensions, { Breakpoints } from 'hooks/useWindowDimensions';
import { CustomSelectInput } from '@components/Forms/FormFields/CustomSelectInput';

interface Props {
  calendarEventInputs: EventInput[];
  calendarRef: React.RefObject<FullCalendar>;
  isLoading: boolean;
  calendarOptions?: CalendarOptions;
  holidays?: Record<string, number[]>;
  onDatesChange: (dates: DatesSetArg) => void;
  createEvent: (e: DateSelectArg) => void;
  onClickEvent: (e: EventClickArg) => void;
  onDragResizeEvent: (e: EventDropArg | EventResizeDoneArg) => void;
  isFetching: boolean;
  selectAllow: AllowFunc;
  eventAllow: AllowFunc;
  swipeable?: boolean;
}

// note on full calendar: if start date is the same as end date, end becomes null and defaults to the defaultTimedEventDuration property
export const Calendar: FunctionComponent<Props> = ({
  calendarEventInputs,
  calendarRef,
  isLoading,
  calendarOptions,
  holidays,
  onDatesChange,
  createEvent,
  onClickEvent,
  onDragResizeEvent,
  isFetching,
  selectAllow,
  eventAllow,
  swipeable,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar',
  });
  const { width } = useWindowDimensions();
  const calendarView = getCalendarView(calendarRef);

  useEffect(() => {
    if (!calendarRef.current) return;
    if (width < Breakpoints.SM && calendarView === CalendarView.WEEK) {
      calendarRef.current
        .getApi()
        .changeView(viewToViewType(CalendarView.THREE_DAYS));
    }
    if (width >= Breakpoints.SM && calendarView === CalendarView.THREE_DAYS) {
      calendarRef.current
        .getApi()
        .changeView(viewToViewType(CalendarView.WEEK));
    }
  }, [width, calendarView, calendarRef]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (!swipeable) return;
      if (!calendarRef.current) return;
      calendarRef.current.getApi().next();
    },
    onSwipedRight: () => {
      if (!swipeable) return;
      if (!calendarRef.current) return;
      calendarRef.current.getApi().prev();
    },
    delta: 20,
    swipeDuration: 400,
  });

  function getEventMaxStack(): number | null {
    switch (calendarView) {
      case CalendarView.DAY:
        return 12;
      case CalendarView.WEEK:
        return 2;
      case CalendarView.THREE_DAYS:
        return 2;
      case CalendarView.MONTH:
        return null;
    }
  }

  function getMoreLinkClicked() {
    if (calendarView === CalendarView.WEEK) return 'day';
    return undefined;
  }

  function onDateClick(arg: DateClickArg) {
    if (!calendarRef.current) return;
    if (calendarView === CalendarView.MONTH) {
      const targetView =
        width < Breakpoints.LG ? CalendarView.DAY : CalendarView.WEEK;
      calendarRef.current.getApi().changeView(viewToViewType(targetView));
      calendarRef.current.getApi().gotoDate(arg.date);
    }
  }

  function isHighlighted(date: Date) {
    return (
      isHoliday(dayjs(date), holidays) ||
      isVacation(dayjs(date), calendarEventInputs)
    );
  }

  return (
    <>
      <CalendarControlRow
        calendarRef={calendarRef}
        calendarView={calendarView}
        options={calendarOptions}
      />
      <div {...swipeHandlers}>
        <div
          className={
            calendarView === CalendarView.MONTH ? 'hidden sm:block' : ''
          }
        >
          <FullCalendar
            ref={calendarRef}
            plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
            selectable={true}
            slotEventOverlap={false}
            eventMaxStack={getEventMaxStack()}
            moreLinkClick={getMoreLinkClicked()}
            moreLinkClassNames={'select-none'}
            nowIndicator={true}
            longPressDelay={400}
            firstDay={1}
            showNonCurrentDates={false}
            slotLabelFormat={{
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            }}
            slotLabelClassNames={'text-sm sm:text-base select-none'}
            eventTimeFormat={{
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            }}
            dayCellClassNames={({ date }) =>
              classNames(
                isHighlighted(date) && '!bg-gray-50',
                calendarView === CalendarView.MONTH && 'cursor-pointer', // show cursor to indicate that clicking navigates to day view
              )
            }
            dateClick={onDateClick}
            allDayText={t('allDay')}
            allDayClassNames={'text-xs sm:text-base select-none'}
            height={calendarOptions?.height || 'auto'}
            select={createEvent}
            events={calendarEventInputs}
            eventContent={(e) => renderEventContent(e, isLoading, calendarView)}
            headerToolbar={false}
            views={{
              timeGridThreeDays: {
                type: 'timeGrid',
                duration: { days: 3 },
              },
            }}
            weekNumbers={true}
            weekNumberContent={(week) => {
              return (
                <span className="flex flex-col text-sm pb-3 font-light text-gray-800 select-none">
                  {t('week')} {week.num}
                </span>
              );
            }}
            fixedWeekCount={false}
            editable={!isLoading && !isFetching}
            eventDrop={onDragResizeEvent}
            defaultTimedEventDuration={'00:01'}
            datesSet={onDatesChange}
            slotMinTime={'00:00:00'}
            slotMaxTime={'24:00:00'}
            locale={t('calendarLocale')}
            displayEventTime={true}
            eventResize={onDragResizeEvent}
            selectAllow={selectAllow}
            eventClick={onClickEvent}
            eventAllow={eventAllow}
            scrollTime={dayjs()
              .subtract(1, 'hour')
              .startOf('hour')
              .format('HH:mm:ss')}
            dayHeaderFormat={{
              weekday: calendarView === CalendarView.DAY ? 'long' : 'short',
            }}
            dayHeaderContent={(dayHeader) => (
              <span className="flex flex-col text-sm pb-3 font-light text-gray-800 select-none">
                {dayHeader.text}
                {calendarView !== CalendarView.MONTH && (
                  <span className="text-2xl font-normal">
                    {dayHeader.date.getDate()}
                  </span>
                )}
              </span>
            )}
            {...calendarOptions}
          />
        </div>
        <div
          className={classNames(
            calendarView === CalendarView.MONTH ? 'sm:hidden' : 'hidden',
          )}
        >
          <MobileMonthCalendar
            year={calendarRef.current?.getApi().view.currentStart.getFullYear()}
            month={calendarRef.current?.getApi().view.currentStart.getMonth()}
            calendarEventInputs={calendarEventInputs}
            onDayClicked={(day, month, year) => {
              if (!calendarRef.current) return;
              calendarRef.current.getApi().gotoDate(new Date(year, month, day));
              calendarRef.current
                .getApi()
                .changeView(viewToViewType(CalendarView.DAY));
            }}
          />
        </div>
      </div>
    </>
  );
};
const CalendarControlRow: FunctionComponent<{
  calendarRef: RefObject<FullCalendar>;
  calendarView: CalendarView;
  options: CalendarOptions;
}> = ({ calendarRef, calendarView, options }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar',
  });

  const { width } = useWindowDimensions();

  const isSmallScreen = width < Breakpoints.SM;

  const Next = () => (
    <a
      className="cursor-pointer border-transparent relative inline-flex items-center p-2 aspect-square text-sm font-medium  border-2 text-accent-600 hover:text-white bg-accent-100 hover:bg-accent-600 rounded-lg ease-in duration-100"
      onClick={() => {
        if (!calendarRef.current) return;
        calendarRef.current.getApi().next();
      }}
    >
      <ChevronRightIcon className="w-5 h-5" />
    </a>
  );
  const Prev = () => (
    <a
      className="cursor-pointer border-transparent relative inline-flex items-center p-2 aspect-square text-sm font-medium border-2 text-accent-600 hover:text-white bg-accent-100 hover:bg-accent-600 rounded-lg ease-in duration-100"
      onClick={() => {
        if (!calendarRef.current) return;
        calendarRef.current.getApi().prev();
      }}
    >
      <ChevronLeftIcon className="w-5 h-5" />
    </a>
  );
  const start =
    calendarRef?.current && calendarRef.current.getApi().view.currentStart;
  const end =
    calendarRef?.current && calendarRef.current.getApi().view.currentEnd;
  const rangeTitle = formatCalendarRangeTitle(start, end, calendarView);

  if (options.headerToolbar === false) return null;

  return (
    <div className="select-none">
      <h2 className="mb-2 text-2xl sm:text-3xl">{rangeTitle}</h2>
      <div className="flex justify-between flex-row w-full mb-4 gap-2">
        <div className="flex flex-row gap-2 flex-grow items-center">
          {isSmallScreen && <Prev />}
          <div className="flex-grow flex justify-center sm:justify-start items-center">
            <GoToDateButton calendarRef={calendarRef} />
            <div className="ml-2 text-sm">
              <CustomSelectInput<CalendarView>
                options={[
                  CalendarView.DAY,
                  isSmallScreen ? CalendarView.THREE_DAYS : CalendarView.WEEK,
                  CalendarView.MONTH,
                ]}
                bindValue={(e) => e}
                bindLabel={(e) => {
                  return {
                    [CalendarView.DAY]: t('dayButton'),
                    [CalendarView.WEEK]: t('weekButton'),
                    [CalendarView.MONTH]: t('monthButton'),
                    [CalendarView.THREE_DAYS]: t('threeDaysButton'),
                  }[e];
                }}
                selected={calendarView}
                onChange={(e) => {
                  if (!calendarRef.current) return;
                  calendarRef.current.getApi().changeView(viewToViewType(e));
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-1 items-center">
          {!isSmallScreen && <Prev />}
          <Next />
        </div>
      </div>
    </div>
  );
};

const GoToDateButton: FunctionComponent<{
  calendarRef: RefObject<FullCalendar>;
}> = ({ calendarRef }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar',
  });

  return (
    <Menu as="div" className="inline-block relative">
      {({ open }) => (
        <>
          <div>
            <div
              className={classNames(
                commonButtonClassesWithoutPadding,
                'text-accent-600 bg-accent-100',
                'cursor-pointer',
              )}
            >
              <div className="flex items-center divide-x-1">
                <div
                  onClick={() => {
                    if (!calendarRef.current) return;
                    calendarRef.current
                      .getApi()
                      .gotoDate(dayjs().format('YYYY-MM-DD'));
                  }}
                  className="px-3 py-2 hover:bg-accent-600 rounded-l-lg hover:text-white"
                >
                  {t('todayButton')}
                </div>
                <Menu.Button className="max-w-xs text-sm border-l hover:border-accent-600 border-accent-500 px-2 py-2 text-accent-600 hover:text-white hover:bg-accent-600 rounded-r-lg">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </Menu.Button>
              </div>
            </div>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                `origin-top-left left-0`,
                'z-50 absolute mt-2 min-w-[12rem] rounded-lg shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-normal',
              )}
            >
              <div className="p-3">
                <BasicTextInput
                  label={t('goToDate')}
                  type="date"
                  small
                  onChange={(e) => {
                    if (!e.target.value) return;
                    const date = dayjs(e.target.value);
                    if (!date.isValid()) return;
                    calendarRef?.current?.getApi().gotoDate(date.toDate());
                  }}
                />
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

Calendar.defaultProps = {
  swipeable: true,
};
