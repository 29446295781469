import React, { FunctionComponent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  CalendarIcon,
  ClipboardDocumentIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import authenticatedFetcher from '../../../../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../../../../data/httpEndpoints';
import { useAppContext } from '../../../../../context/appContext';
import { canEditEvent } from '../../../helpers/CalendarAccessRightsHelpers';
import {
  AdditionalOption,
  CalendarPopupWrapper,
} from '../../Common/CalendarPopupWrapper';

import { CalendarSelection } from '../../Common/CalendarSelection';
import { LoadingIndicator } from '../../../../Common/LoadingIndicator';
import { Tab, TabList } from '../../../../Common/Navigation/Tabs';
import { useNewEventAttempt } from '../../../../../hooks/Exams/useNewEventAttempt';
import useUserPermission from '../../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../../typings/roleConfig';
import useContract from '../../../../../hooks/useContract';
import { useUserTeam } from '../../../../../hooks/useUserTeam';
import { hasReadWriteAccessTo } from '../../../../../utilities/access-rights/hasReadWriteAccessTo';
import { EventDetails } from './EventDetails';
import { ExamAttemptDetail } from './ExamAttemptDetails';
import { ExamAttemptCreateForm } from './ExamAttemptCreate';
import { ExamAttemptEdit } from './ExamAttemptEdit';
import { Resource, WorkEvent } from '@tr-types/backend-types';
import { displayDateFormat } from 'utilities/dateFormat';

interface Props {
  position: [number, number];
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
  onCopy: () => void;
  calendarSelection: CalendarSelection;
}

export enum WorkEventPopupTab {
  examAttemptDetails = 'examAttemptDetails',
  examAttemptCreate = 'examAttemptCreate',
  eventDetails = 'eventDetails',
}

export const WorkEventViewPopup: FunctionComponent<Props> = ({
  position,
  isOpen,
  onClose,
  onEdit: onEventEdit,
  onCopy,
  calendarSelection,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewWorkEvent',
  });
  const [eventIsEditable, setEventIsEditable] = useState(true);
  const [tab, setTab] = useState(WorkEventPopupTab.eventDetails);
  const [isEditingExamAttempt, setIsEditingExamAttempt] = useState(false);
  const [canCopyEvent, setCanCopyEvent] = useState(false);
  const canEditExams = useUserPermission(
    Permission.STUDENT_EXAMS,
    Scope.READ_WRITE,
  );

  const { user: loggedInUser } = useAppContext();
  const loggedInUserTeam = useUserTeam();

  const { contract } = useContract();

  const { data: fetchedEvent } = useSWR<WorkEvent>(
    () =>
      HttpEndpoints.WorkEventEndpoints.getWorkEventById(
        calendarSelection.eventId,
        calendarSelection.userId,
      ),
    authenticatedFetcher,
  );

  useEffect(() => {
    canEditEvent(fetchedEvent, loggedInUser, loggedInUserTeam, contract).then(
      setEventIsEditable,
      noop,
    );
    if (fetchedEvent) {
      setCanCopyEvent(
        hasReadWriteAccessTo(
          Resource.Calendar,
          loggedInUser,
          loggedInUserTeam,
          fetchedEvent?.user,
          contract,
        ),
      );
    }
  }, [fetchedEvent, loggedInUser, contract, loggedInUserTeam]);

  function getEventPopupHeaderText(): string {
    if (!fetchedEvent) return t('loadingLabel');
    return `${dayjs(fetchedEvent.start_time).format(
      displayDateFormat,
    )}, ${dayjs(fetchedEvent.start_time).format('HH:mm')}-${dayjs(
      fetchedEvent.end_time,
    ).format('HH:mm')}`;
  }

  const editEventAction = {
    action: onEventEdit,
    icon: (
      <PencilIcon
        className="h-5 w-5 mx-2"
        aria-hidden="true"
        data-cy="edit-icon"
      />
    ),
    altText: t('editAltText'),
  };

  const copyAction = {
    action: onCopy,
    icon: <ClipboardDocumentIcon className="h-5 w-5 mx-2" aria-hidden="true" />,
    altText: t('copyAltText'),
  };

  const editAttemptAction = {
    action: () => setIsEditingExamAttempt(true),
    icon: (
      <PencilIcon
        className="h-5 w-5 mx-2"
        aria-hidden="true"
        data-cy="attempt-edit-icon"
      />
    ),
    altText: t('editAltText'),
  };

  const additionalActions: AdditionalOption[] = [];
  if (tab === WorkEventPopupTab.eventDetails && canCopyEvent) {
    additionalActions.push(copyAction);
  }
  if (tab === WorkEventPopupTab.eventDetails && eventIsEditable) {
    additionalActions.push(editEventAction);
  }
  if (
    tab === WorkEventPopupTab.examAttemptDetails &&
    !isEditingExamAttempt &&
    canEditExams
  ) {
    additionalActions.push(editAttemptAction);
  }

  return (
    <CalendarPopupWrapper
      position={position}
      onClose={onClose}
      isOpen={isOpen}
      icon={CalendarIcon}
      title={getEventPopupHeaderText()}
      additionalActions={additionalActions}
    >
      {fetchedEvent ? (
        <WorkEventPopupContent
          isEditingExamAttempt={isEditingExamAttempt}
          fetchedEvent={fetchedEvent}
          onTabChange={setTab}
          defaultSelectedTab={tab}
          setIsEditingExamAttempt={setIsEditingExamAttempt}
        />
      ) : (
        <LoadingIndicator />
      )}
    </CalendarPopupWrapper>
  );
};

interface WorkEventPopupContentProps {
  fetchedEvent: WorkEvent;
  onTabChange: (tab: WorkEventPopupTab) => void;
  defaultSelectedTab: WorkEventPopupTab;
  isEditingExamAttempt: boolean;
  setIsEditingExamAttempt: (isEditing: boolean) => void;
}

const WorkEventPopupContent: FunctionComponent<WorkEventPopupContentProps> = ({
  fetchedEvent,
  onTabChange,
  defaultSelectedTab,
  isEditingExamAttempt,
  setIsEditingExamAttempt,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewWorkEvent',
  });

  const { examAttemptIsPossible } = useNewEventAttempt(fetchedEvent);
  const canSeeExams = useUserPermission(Permission.STUDENT_EXAMS, Scope.READ);
  const canEditExams = useUserPermission(
    Permission.STUDENT_EXAMS,
    Scope.READ_WRITE,
  );

  if (
    !canSeeExams ||
    (!fetchedEvent.examAttempt && !examAttemptIsPossible) ||
    (!fetchedEvent.examAttempt && !canEditExams)
  ) {
    return <EventDetails fetchedEvent={fetchedEvent} />;
  }

  if (!fetchedEvent.examAttempt) {
    return (
      <TabList<WorkEventPopupTab>
        defaultSelected={defaultSelectedTab}
        onChange={onTabChange}
      >
        <Tab id={WorkEventPopupTab.eventDetails} title={t('eventDetails')}>
          <EventDetails fetchedEvent={fetchedEvent} />
        </Tab>
        <Tab
          id={WorkEventPopupTab.examAttemptCreate}
          title={'+ ' + t('examAttemptDetails')}
        >
          <ExamAttemptCreateForm
            changeTab={onTabChange}
            workEvent={fetchedEvent}
          />
        </Tab>
      </TabList>
    );
  }

  return (
    <TabList<WorkEventPopupTab>
      defaultSelected={defaultSelectedTab}
      onChange={onTabChange}
    >
      <Tab id={WorkEventPopupTab.eventDetails} title={t('eventDetails')}>
        <EventDetails fetchedEvent={fetchedEvent} />
      </Tab>
      <Tab
        id={WorkEventPopupTab.examAttemptDetails}
        title={t('examAttemptDetails')}
      >
        {isEditingExamAttempt ? (
          <ExamAttemptEdit
            event={fetchedEvent}
            onCloseForm={() => setIsEditingExamAttempt(false)}
            changeTab={onTabChange}
          />
        ) : (
          <ExamAttemptDetail event={fetchedEvent} />
        )}
      </Tab>
    </TabList>
  );
};
