import {
  AccessRight,
  AppUsageContract,
  Resource,
  User,
} from '../../typings/backend-types';
import { Permission, Scope } from '../../typings/roleConfig';

export function hasAccessTo(
  resource: Resource,
  accessRight: AccessRight,
  currentUser: User,
  currentUserTeam: User[],
  otherUser: { id: string },
  orgContract: AppUsageContract | null,
): boolean {
  if (!(currentUser && otherUser)) {
    throw new Error('One or more arguments is undefined or null');
  }
  // superiors have write access to calendars and read access to time-tracking of their team members
  if (
    currentUserTeam.some((u) => u.id === otherUser.id) &&
    (resource === Resource.Calendar || accessRight === AccessRight.Read)
  )
    return true;
  const correspondingScope =
    accessRight === AccessRight.Read ? Scope.READ : Scope.READ_WRITE;
  if (correspondingScope > Scope.READ && !orgContract) return false;
  if (currentUser.role.isAdmin) return true;
  let correspondingPermission: Permission | undefined;
  switch (resource) {
    case Resource.Calendar: {
      correspondingPermission =
        currentUser.id === otherUser.id
          ? Permission.OWN_CALENDAR
          : Permission.ALL_CALENDARS;
      break;
    }
    case Resource.TimeTracking: {
      correspondingPermission =
        currentUser.id === otherUser.id
          ? Permission.OWN_TIME_TRACKING
          : Permission.ALL_TIME_TRACKING;
      break;
    }
  }
  if (
    currentUser.role.permissions[correspondingPermission] >= correspondingScope
  )
    return true;

  return (
    currentUser?.access_rights?.some(
      (right) =>
        right.accessed_user?.id === otherUser.id &&
        right.resource === resource &&
        right.access_right === accessRight,
    ) ?? false
  );
}
