import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useSWR from 'swr';
import FullCalendar, { AllowFunc, EventClickArg } from '@fullcalendar/react';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import {
  EventInput,
  CalendarOptions,
  DatesSetArg,
  EventDropArg,
} from '@fullcalendar/common';
import {
  EllipsisVerticalIcon,
  LockClosedIcon,
} from '@heroicons/react/24/solid';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import router, { useRouter } from 'next/router';
import { DateSelectArg } from '@fullcalendar/core';
import { addBreadcrumb, setContext } from '@sentry/nextjs';
import { useTranslation } from 'react-i18next';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { hasReadWriteAccessTo } from '../../utilities/access-rights/hasReadWriteAccessTo';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import useLock from '../../hooks/useLock';
import { DropdownMenu } from '../Common/Dropdown/DropdownMenu';
import { Routes } from '../../utilities/routes';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import useContract from '../../hooks/useContract';
import { useUserTeam } from '../../hooks/useUserTeam';
import { hasWriteAccessTo } from '../../utilities/access-rights/hasWriteAccessTo';
import {
  calculatePopupPosition,
  getIcalSubscriptionIds,
  mapHolidaysToEventInputs,
  mapIcalEventsToEventInputs,
  mapWorkEventsToEventInputs,
  mapVacationEventsToEventInputs,
  mapWorkEventToEventInput,
  eventHasPossibleTimespan,
} from './helpers/CalendarHelpers';
import { canEditWithoutLocks } from './helpers/CalendarAccessRightsHelpers';
import {
  CalendarSelection,
  EventType,
} from './EventPopups/Common/CalendarSelection';
import { CalendarPopups } from './CalendarPopups';
import { Calendar } from './Calendar';
import { validateMoveOrCopyEvent } from './helpers/validateMoveEvent';
import { CalendarView, getCalendarView } from './helpers/getCalendarView';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';
import authenticatedFetcher from 'data/authenticatedFetcher';
import authenticatedPost from 'data/authenticatedPost';
import { HttpEndpoints } from 'data/httpEndpoints';
import {
  WorkEvent,
  UpdateWorkEventDto,
  UpdateVacationEventsDto,
  Resource,
  ICalSubscription,
  ICalEvent,
  VacationEvent,
  CalendarEntry,
  LockableAction,
  BasicUserInfo,
  AccessRight,
} from '@tr-types/backend-types';
import { InfoText } from '@components/Common/InfoText';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { useAppContext } from 'context/appContext';
import { CalendarOverrideContextWrapper } from 'context/calendarOverrideContext';
import { useConfirmPopupContext } from 'context/confirmPopupContext';
import { hasAccessTo } from 'utilities/access-rights/hasAccessTo';

export type CalendarFetchUrlBuilder = (
  from?: dayjs.Dayjs,
  to?: dayjs.Dayjs,
) => (() => string | undefined) | null; // We need this to return another function for the useSWR hooks.

interface Props {
  // url to fetch the calendar events
  workEventsFetchUrl: CalendarFetchUrlBuilder;
  vacationEventsFetchUrl: CalendarFetchUrlBuilder;
  icalSubscriptions?: ICalSubscription[];
  calendarOptions?: CalendarOptions;
  // default vehicle that will be selected for new events
  overrideVehicleId?: string;
  // "overrideUser" is from the selected user and "user" is the user using the webapp
  overrideUsers?: BasicUserInfo[];
  // callback to switch the calendar view mode to show all events, used for deeplinks of other users
  onDeeplinkEvent?: (entry: CalendarEntry) => void;
  swipeable?: boolean;
  lessonRequestId?: string;
}

export const CalendarWithPopups: FunctionComponent<Props> = ({
  workEventsFetchUrl,
  vacationEventsFetchUrl,
  icalSubscriptions,
  calendarOptions,
  overrideVehicleId,
  overrideUsers,
  onDeeplinkEvent,
  swipeable,
  lessonRequestId,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar',
  });

  const { setErrorMessage: setError } = useErrorPopupContext();
  const { user: loggedInUser, organization } = useAppContext();
  const loggedInUserTeam = useUserTeam();
  const canEditOwnVacationEvents = useUserPermission(
    Permission.OWN_VACATION,
    Scope.READ_WRITE,
  );
  const canEditPastEvents = useUserPermission(
    Permission.EDIT_PAST_EVENTS,
    Scope.READ_WRITE,
  );
  const { contract } = useContract();
  const calendarRef = useRef<FullCalendar>();
  const router = useRouter();

  const { confirm } = useConfirmPopupContext();

  // Hooks used for calendar functionality
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calendarEventInputs, setCalendarEventInputs] =
    useState<EventInput[]>(null);
  const [viewStartDate, setViewStartDate] = useState<Dayjs>(dayjs().day(0));
  const [viewEndDate, setViewEndDate] = useState<Dayjs>(dayjs().day(6));

  // Manage state of the popup
  const [popupPosition, setPopupPosition] = useState<[number, number]>(null);
  const [calendarSelection, setCalendarSelection] =
    useState<CalendarSelection>(null);

  // Sentry additional info
  useEffect(() => {
    if (!calendarSelection) {
      addBreadcrumb({ category: 'calendar', message: 'Unselected event' });
    } else {
      addBreadcrumb({
        category: 'calendar',
        message: 'Selected event',
        data: calendarSelection,
      });
    }
    setContext('calendarSelection', calendarSelection);
  }, [calendarSelection]);

  // Initial data fetch hooks
  const {
    data: workEvents,
    mutate: reloadWorkEvents,
    isValidating: workEventsValidating,
  } = useSWR<WorkEvent[]>(
    workEventsFetchUrl(viewStartDate, viewEndDate),
    authenticatedFetcher,
    {
      onError: () => {
        setError(t('errorFetchEvents'));
      },
    },
  );

  const calendarViewInterval: ManipulateType =
    getCalendarView(calendarRef) === CalendarView.DAY ||
    getCalendarView(calendarRef) === CalendarView.THREE_DAYS
      ? 'days'
      : getCalendarView(calendarRef) === CalendarView.WEEK
      ? 'weeks'
      : 'months';

  const calendarViewIntervalNumber =
    getCalendarView(calendarRef) === CalendarView.THREE_DAYS ? 3 : 1;

  // prefetch work events for the next and previous "pages"
  useSWR<WorkEvent[]>(
    workEventsFetchUrl(
      viewStartDate.subtract(calendarViewIntervalNumber, calendarViewInterval),
      viewEndDate.subtract(calendarViewIntervalNumber, calendarViewInterval),
    ),
    authenticatedFetcher,
  );
  useSWR<WorkEvent[]>(
    workEventsFetchUrl(
      viewStartDate.add(calendarViewIntervalNumber, calendarViewInterval),
      viewEndDate.add(calendarViewIntervalNumber, calendarViewInterval),
    ),
    authenticatedFetcher,
  );

  const {
    data: vacationEvents,
    mutate: reloadVacationEvents,
    isValidating: vacationEventsValidating,
  } = useSWR<VacationEvent[]>(
    vacationEventsFetchUrl(viewStartDate, viewEndDate),
    authenticatedFetcher,
    {
      onError: () => {
        setError(t('errorFetchVacationEvents'));
      },
    },
  );

  // prefetch vacation events for the next and previous "pages"
  useSWR<VacationEvent[]>(
    vacationEventsFetchUrl(
      viewStartDate.subtract(calendarViewIntervalNumber, calendarViewInterval),
      viewEndDate.subtract(calendarViewIntervalNumber, calendarViewInterval),
    ),
    authenticatedFetcher,
  );
  useSWR<VacationEvent[]>(
    vacationEventsFetchUrl(
      viewStartDate.add(calendarViewIntervalNumber, calendarViewInterval),
      viewEndDate.add(calendarViewIntervalNumber, calendarViewInterval),
    ),
    authenticatedFetcher,
  );

  const icalEventsFetchUrl = useCallback(
    (from: Dayjs, to: Dayjs) =>
      icalSubscriptions?.length > 0
        ? HttpEndpoints.UserEndpoints.iCalSubscriptions.getPrivateEventsForUser(
            loggedInUser.id,
            from.format(),
            to.format(),
            getIcalSubscriptionIds(icalSubscriptions),
          )
        : null,
    [icalSubscriptions, loggedInUser.id],
  );

  const { data: icalEvents, isValidating: icalEventsValidating } = useSWR<
    ICalEvent[]
  >(icalEventsFetchUrl(viewStartDate, viewEndDate), authenticatedFetcher, {
    onError: () => {
      setError(t('errorFetchICalEvents'));
    },
  });

  // prefetch ical events for the next and previous "pages"
  useSWR(
    icalEventsFetchUrl(
      viewStartDate.subtract(calendarViewIntervalNumber, calendarViewInterval),
      viewEndDate.subtract(calendarViewIntervalNumber, calendarViewInterval),
    ),
    authenticatedFetcher,
  );
  useSWR(
    icalEventsFetchUrl(
      viewStartDate.add(calendarViewIntervalNumber, calendarViewInterval),
      viewEndDate.add(calendarViewIntervalNumber, calendarViewInterval),
    ),
    authenticatedFetcher,
  );

  useEffect(() => {
    let holidayEventInputs: EventInput[] = [];
    let workEventInputs: EventInput[] = [];
    let vacationEventInputs: EventInput[] = [];
    let icalEventInputs: EventInput[] = [];

    if (organization?.holidays) {
      holidayEventInputs = mapHolidaysToEventInputs(
        viewStartDate,
        viewEndDate,
        organization?.holidays,
      );
    }
    if (workEvents) {
      workEventInputs = mapWorkEventsToEventInputs(workEvents);
    }
    if (vacationEvents) {
      vacationEventInputs = mapVacationEventsToEventInputs(vacationEvents);
    }
    if (icalEvents) {
      icalEventInputs = mapIcalEventsToEventInputs(
        icalEvents,
        icalSubscriptions,
      );
    }

    setCalendarEventInputs(
      workEventInputs
        .concat(icalEventInputs)
        .concat(holidayEventInputs)
        .concat(vacationEventInputs),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workEvents, icalEvents, icalSubscriptions, organization, vacationEvents]);

  function createEvent(e: DateSelectArg) {
    if (!!calendarSelection) {
      setCalendarSelection(null);
      return;
    }
    if (
      overrideUsers &&
      overrideUsers.every(
        (u) =>
          !hasReadWriteAccessTo(
            Resource.Calendar,
            loggedInUser,
            loggedInUserTeam,
            u,
            contract,
          ),
      )
    ) {
      setError(t('errorCannotModifyEventForUser'));
      return;
    }

    setCalendarSelection({
      allDay: e.allDay,
      eventType: e.allDay ? EventType.VACATION : EventType.WORK,
      start: dayjs(e.start),
      end: dayjs(e.end),
      lessonRequestId: !e.allDay && lessonRequestId,
      userId: overrideUsers.some((u) => u.id === loggedInUser.id)
        ? loggedInUser.id
        : overrideUsers[0].id,
    });
    setPopupPosition(calculatePopupPosition(e.jsEvent));
  }

  async function openEvent(e: EventClickArg) {
    if (!e.event.id) return;
    if (isLoading) return;

    const eventType = (() => {
      if (e.event.extendedProps?.isWorkEvent) return EventType.WORK;
      if (e.event.extendedProps?.isVacationEvent) return EventType.VACATION;
      if (e.event.extendedProps?.isIcalEvent) return EventType.ICAL;
      throw new Error('Invalid event type');
    })();

    setCalendarSelection({
      eventId: e.event.id,
      allDay: e.event.allDay,
      eventType,
      start: dayjs(e.event.start),
      end: dayjs(e.event.end),
      eventTitle: e.event.title,
      userId: e.event.extendedProps?.userId,
    });
    setPopupPosition(calculatePopupPosition(e.jsEvent));
  }

  async function showDeepLinkedEvent(eventId: string) {
    if (calendarSelection?.eventId === eventId) return;
    const event: WorkEvent = await authenticatedFetcher(
      HttpEndpoints.CalendarEntryEndpoints.getCalendarEntryById(eventId),
    );
    if (!event) return;

    if (
      event.user &&
      !hasAccessTo(
        Resource.Calendar,
        AccessRight.Read,
        loggedInUser,
        loggedInUserTeam,
        event.user,
        contract,
      )
    ) {
      setError(t('errorInsufficientPermissions'));
      return;
    }

    void openEvent({
      event: mapWorkEventToEventInput(event),
      jsEvent: {
        x: (window.innerWidth - 300) / 2,
        y: window.innerHeight / 2,
      },
    } as EventClickArg);
    if (event.user.id !== loggedInUser.id) onDeeplinkEvent?.(event);
    if (!calendarRef.current) return;
    calendarRef.current.getApi().gotoDate(dayjs(event.start_time).toDate());
  }

  useEffect(() => {
    const linkedEvent = router.query.event as string;
    if (linkedEvent) {
      void showDeepLinkedEvent(linkedEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  function updateDateRange(dates: DatesSetArg): void {
    setViewStartDate(dayjs(dates.start));
    setViewEndDate(dayjs(dates.end).subtract(1, 'day').endOf('day'));
  }

  async function moveEvent(e: EventDropArg | EventResizeDoneArg) {
    setIsLoading(true);
    const calendarEntry: CalendarEntry = await authenticatedFetcher(
      HttpEndpoints.CalendarEntryEndpoints.getCalendarEntryById(e.event.id),
    );
    // validate whether moving the event is intended and allowed
    const error = await validateMoveOrCopyEvent(
      e,
      calendarEntry,
      confirm,
      loggedInUser,
      loggedInUserTeam,
      'move',
      contract,
    );
    if (error != null) {
      if (error) setError(error);
      setIsLoading(false);
      e.revert();
      return;
    }

    // actually sending the request
    try {
      if (e.event.allDay) {
        const updateEvent: UpdateVacationEventsDto = {
          start_time: dayjs(e.event.startStr).startOf('day').toISOString(),
          end_time: dayjs(e.event.endStr || e.event.startStr)
            .startOf('day')
            .toISOString(),
        };
        await authenticatedPost(
          HttpEndpoints.VacationEventEndpoints.patchVacationEvent(
            e.event.id,
            calendarEntry.user.id,
          ),
          updateEvent,
          'PATCH',
        );
        await reloadVacationEvents();
      } else {
        const updateEvent: UpdateWorkEventDto = {
          start_time: dayjs(e.event.startStr).toISOString(),
          end_time: dayjs(e.event.endStr || e.event.startStr).toISOString(),
        };
        await authenticatedPost(
          HttpEndpoints.WorkEventEndpoints.patchWorkEvent(
            e.event.id,
            calendarEntry.user.id,
          ),
          updateEvent,
          'PATCH',
        );
        await reloadWorkEvents();
      }
    } catch (err) {
      e.revert();
      setError(t('errorCouldNotMoveEvent'));
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  const { isLocked: viewStartDateIsLocked } = useLock(
    LockableAction.CALENDAR_EDITS,
    {
      start: viewStartDate.format('YYYY-MM-DD'),
      end: viewStartDate.format('YYYY-MM-DD'),
    },
  );
  const { isLocked: viewEndDateIsLocked } = useLock(
    LockableAction.CALENDAR_EDITS,
    {
      start: viewEndDate.format('YYYY-MM-DD'),
      end: viewEndDate.format('YYYY-MM-DD'),
    },
  );

  const lockMessage = useMemo(() => {
    if (dayjs().diff(viewStartDate, 'year') >= 1) {
      return t('errorCannotEditOlderThanOneYear');
    }
    const months: string[] = [];
    if (viewStartDateIsLocked) {
      months.push(dayjs().month(viewStartDate.get('month')).format('MMMM'));
    }
    if (viewStartDate.month() !== viewEndDate.month() && viewEndDateIsLocked) {
      months.push(dayjs().month(viewEndDate.get('month')).format('MMMM'));
    }
    if (months.length === 0) return null;
    return t('lockMessage', {
      months: months.join(` ${t('and')} `),
      count: months.length,
    });
  }, [
    viewStartDateIsLocked,
    viewEndDateIsLocked,
    t,
    viewStartDate,
    viewEndDate,
  ]);

  const calendarView = getCalendarView(calendarRef);

  function periodInViewIsLocked(start: Date | Dayjs, end: Date | Dayjs) {
    if (
      dayjs(start).month() === viewStartDate.month() &&
      viewStartDateIsLocked
    ) {
      return true;
    }
    if (dayjs(end).month() === viewEndDate.month() && viewEndDateIsLocked) {
      return true;
    }
    return false;
  }

  const selectAllow: AllowFunc = (dateSpanDuringDrag) => {
    if (calendarView == CalendarView.MONTH) return false;
    if (overrideVehicleId && dateSpanDuringDrag.allDay) return false;
    if (!overrideUsers?.length) return false;
    // check locks
    if (
      periodInViewIsLocked(
        dateSpanDuringDrag.start,
        // if the event is all day, the end date is the day after the event
        dateSpanDuringDrag.allDay
          ? dayjs(dateSpanDuringDrag.end).subtract(1, 'd')
          : dateSpanDuringDrag.end,
      )
    ) {
      return false;
    }
    // users cannot edit past events unless they have the permission
    if (
      !canEditPastEvents &&
      dayjs(dateSpanDuringDrag.start).isBefore(dayjs().startOf('day'))
    ) {
      return false;
    }
    // users can only create vacation events for themselves if they have the permission
    if (
      dateSpanDuringDrag.allDay &&
      overrideUsers.every((u) => u.id === loggedInUser.id) &&
      !canEditOwnVacationEvents
    ) {
      return false;
    }
    // no vacation events for lesson requests
    if (lessonRequestId && dateSpanDuringDrag.allDay) return false;
    // disable selection if the user cannot edit any of the selected users' calendar
    if (
      overrideUsers.every(
        (u) =>
          !hasWriteAccessTo(
            Resource.Calendar,
            loggedInUser,
            loggedInUserTeam,
            u,
            contract,
          ),
      )
    ) {
      return false;
    }
    return (
      eventHasPossibleTimespan(dateSpanDuringDrag) &&
      canEditWithoutLocks(dayjs(dateSpanDuringDrag.start), loggedInUser)
    );
  };

  // function that determines whether an event can be moved from FullCalendar's perspective
  const eventAllow: AllowFunc = (dateSpanDuringDrag, eventBeforeDrag) => {
    if (calendarView === CalendarView.MONTH) return false;
    if (!eventHasPossibleTimespan(dateSpanDuringDrag)) return false;
    // check lock periods
    if (
      periodInViewIsLocked(
        dateSpanDuringDrag.start,
        // if the event is all day, the end date is the day after the event
        dateSpanDuringDrag.allDay
          ? dayjs(dateSpanDuringDrag.end).subtract(1, 'd')
          : dateSpanDuringDrag.end,
      ) ||
      periodInViewIsLocked(
        eventBeforeDrag.start,
        dateSpanDuringDrag.allDay
          ? dayjs(dateSpanDuringDrag.end).subtract(1, 'd')
          : dateSpanDuringDrag.end,
      )
    ) {
      return false;
    }
    // users cannot edit past events unless they have the permission
    if (
      !canEditPastEvents &&
      (dayjs(dateSpanDuringDrag.start).isBefore(dayjs().startOf('day')) ||
        dayjs(eventBeforeDrag.start).isBefore(dayjs().startOf('day')))
    ) {
      return false;
    }
    // prevent dragging if the user cannot edit any of the selected users' calendars
    // NOTE: this is not a full security check, just for UX purposes so that we can intercept dragging as early as possible
    if (
      !overrideUsers ||
      overrideUsers.every(
        (u) =>
          !hasWriteAccessTo(
            Resource.Calendar,
            loggedInUser,
            loggedInUserTeam,
            u,
            contract,
          ),
      )
    ) {
      return false;
    }
    if (
      eventBeforeDrag.extendedProps.userId &&
      !hasWriteAccessTo(
        Resource.Calendar,
        loggedInUser,
        loggedInUserTeam,
        { id: eventBeforeDrag.extendedProps.userId },
        contract,
      )
    ) {
      return false;
    }
    if (
      dateSpanDuringDrag.allDay &&
      !canEditOwnVacationEvents &&
      overrideUsers.every((u) => u.id === loggedInUser.id)
    ) {
      return false;
    }
    // check that the user can edit in the new and old timespans
    return (
      canEditWithoutLocks(dayjs(dateSpanDuringDrag.start), loggedInUser) &&
      canEditWithoutLocks(dayjs(eventBeforeDrag.start), loggedInUser)
    );
  };

  return (
    <CalendarOverrideContextWrapper
      overrideUsers={overrideUsers}
      overrideVehicleId={overrideVehicleId}
    >
      <CalendarInfoRow
        lockMessage={lockMessage}
        isFetchingEvents={
          icalEventsValidating ||
          workEventsValidating ||
          vacationEventsValidating
        }
      />

      {
        // only display the calendar once the entries are loaded
        calendarEventInputs ? (
          <Calendar
            calendarEventInputs={calendarEventInputs}
            calendarRef={calendarRef}
            calendarOptions={calendarOptions}
            holidays={organization.holidays}
            isLoading={isLoading}
            selectAllow={selectAllow}
            eventAllow={eventAllow}
            isFetching={
              icalEventsValidating ||
              workEventsValidating ||
              vacationEventsValidating
            }
            onDatesChange={updateDateRange}
            createEvent={createEvent}
            onClickEvent={openEvent}
            onDragResizeEvent={moveEvent}
            swipeable={swipeable}
          />
        ) : (
          <LoadingIndicator />
        )
      }
      <CalendarPopups
        popupPosition={popupPosition}
        calendarSelection={calendarSelection}
        setIsLoading={setIsLoading}
        onEventsChanged={() => {
          void reloadWorkEvents();
          void reloadVacationEvents();
        }}
        onPopupClose={() => {
          setPopupPosition(null);
          setCalendarSelection(null);
          setIsLoading(false);
        }}
      />
    </CalendarOverrideContextWrapper>
  );
};

const CalendarInfoRow: FunctionComponent<{
  lockMessage: string;
  isFetchingEvents: boolean;
}> = ({ lockMessage, isFetchingEvents }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar',
  });

  return (
    <div className="w-full h-5 flex items-center flex-row justify-between mb-2">
      <div className="flex">
        {lockMessage && (
          <InfoText icon={LockClosedIcon}>{lockMessage}</InfoText>
        )}
      </div>
      <div className="flex items-center pt-1">
        {isFetchingEvents && (
          <InfoText
            icon={() => (
              <div className="inline-block mr-3 w-4 h-4">
                <LoadingIndicator size={4} thickness={2} />
              </div>
            )}
          >
            {t('fetchingEvents')}
          </InfoText>
        )}
        <DropdownMenu
          options={[
            {
              icon: ArrowTopRightOnSquareIcon,
              label: t('shareCalendar'),
              onClick: () =>
                void router.push(Routes.Profile.EditView + '#calendarExports'),
            },
          ]}
        >
          <div className="ml-2 p-2 hover:bg-gray-200 rounded-full">
            <EllipsisVerticalIcon className="w-5 h-5" />
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};
