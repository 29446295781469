import React, { FunctionComponent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PencilIcon, SunIcon } from '@heroicons/react/24/outline';

import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import authenticatedFetcher from '../../../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../../../data/httpEndpoints';
import { useAppContext } from '../../../../context/appContext';
import { canEditEvent } from '../../helpers/CalendarAccessRightsHelpers';
import { CalendarPopupWrapper } from '../Common/CalendarPopupWrapper';
import { LoadingIndicator } from '../../../Common/LoadingIndicator';
import { CalendarSelection } from '../Common/CalendarSelection';

import useContract from '../../../../hooks/useContract';
import { useUserTeam } from '../../../../hooks/useUserTeam';
import { EventDescriptionItem } from './EventDescriptionItem';
import { VacationEvent } from '@tr-types/backend-types';
import { displayDateFormat } from 'utilities/dateFormat';
import { formatName } from 'utilities/formatName';

interface Props {
  position: [number, number];
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
  calendarSelection: CalendarSelection;
}

export const VacationEventViewPopup: FunctionComponent<Props> = ({
  position,
  isOpen,
  onClose,
  onEdit,
  calendarSelection,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewVacation',
  });
  const [editable, setEditable] = useState(true);

  const { user: loggedInUser } = useAppContext();
  const loggedInUserTeam = useUserTeam();
  const { contract } = useContract();

  const { data: fetchedEvent } = useSWR<VacationEvent>(
    () =>
      HttpEndpoints.VacationEventEndpoints.getVacationEventById(
        calendarSelection.eventId,
        calendarSelection.userId,
      ),
    authenticatedFetcher,
  );

  useEffect(() => {
    canEditEvent(fetchedEvent, loggedInUser, loggedInUserTeam, contract).then(
      setEditable,
      noop,
    );
  }, [fetchedEvent, loggedInUser, contract, loggedInUserTeam]);

  function getVacationTimeString() {
    const endDate = dayjs(fetchedEvent.end_time).subtract(1, 'day');
    const startDate = dayjs(fetchedEvent.start_time);
    if (startDate.year() !== endDate.year()) {
      return `${startDate.format(displayDateFormat)}-${endDate.format(
        displayDateFormat,
      )}`;
    }
    if (startDate.month() !== endDate.month()) {
      return `${startDate.format('DD.MM.')}-${endDate.format(
        displayDateFormat,
      )}`;
    }
    if (startDate.date() !== endDate.date()) {
      return `${startDate.format('DD.')}-${endDate.format(displayDateFormat)}`;
    }
    return startDate.format(displayDateFormat);
  }

  function getEventPopupHeaderText(): string {
    if (!fetchedEvent) return t('loadingLabel');

    return t('header', { time: getVacationTimeString() });
  }

  const editAction = {
    action: onEdit,
    icon: (
      <PencilIcon
        className="h-5 w-5 mx-2"
        aria-hidden="true"
        data-cy="edit-icon"
      />
    ),
    altText: t('editAltText'),
  };

  return (
    <CalendarPopupWrapper
      position={position}
      isOpen={isOpen}
      icon={SunIcon}
      onClose={onClose}
      title={getEventPopupHeaderText()}
      additionalActions={editable ? [editAction] : []}
    >
      {fetchedEvent ? (
        <EventDetails fetchedEvent={fetchedEvent} />
      ) : (
        <LoadingIndicator />
      )}
    </CalendarPopupWrapper>
  );
};

const EventDetails: FunctionComponent<{ fetchedEvent: VacationEvent }> = ({
  fetchedEvent,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewVacation',
  });

  return (
    <div className="divide-y">
      {fetchedEvent.description && (
        <EventDescriptionItem label={t('descriptionLabel')}>
          <div className="text-l">{fetchedEvent.description}</div>
        </EventDescriptionItem>
      )}
      {fetchedEvent.user && (
        <EventDescriptionItem label={t('userLabel')}>
          <div>{formatName(fetchedEvent.user)}</div>
        </EventDescriptionItem>
      )}
    </div>
  );
};
