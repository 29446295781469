import { Dayjs } from 'dayjs';

export enum EventType {
  WORK,
  VACATION,
  ICAL,
}

/**
 * CalendarSelection represents a selection in the calendar
 * that was made either by dragging on free space or by clicking on an event.
 * It contains information on the type of event, start and end times and if applicable
 * the event itself.
 * Calendar selection is meant to be event agnostic, thus it only contains information
 * common to all events. To get information specific to the event, use the eventId to fetch
 * the event from the backend.
 */
export interface CalendarSelection {
  allDay: boolean;
  start: Dayjs;
  end: Dayjs;
  eventType: EventType;

  // optional information about the selected event
  eventId?: string;
  eventTitle?: string;
  eventDescription?: string;
  lessonRequestId?: string;

  userId: string;
}
